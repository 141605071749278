import React, { Component } from 'react';
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'
 
const AnyReactComponent = ({ text }) => <GreatPlaceStyle><span>{text}</span></GreatPlaceStyle>;

const createMapOptions = (maps) => {
	return {
		mapTypeControlOptions: {
			position: maps.ControlPosition.TOP_RIGHT,
		},
		mapTypeControl: false,
		zoomControl: true,
		scaleControl: false,
		streetViewControl: false,
		fullscreenControl: false,
		styles: [
			{
				featureType: 'water',
				elementType: 'geometry',
				stylers: [
					{
						color: '#e9e9e9',
					},
					{
						lightness: 20,
					},
				],
			},
			{
				featureType: 'landscape',
				elementType: 'geometry',
				stylers: [
					{
						color: '#f5f5f5',
					},
					{
						lightness: 20,
					},
				],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry.fill',
				stylers: [
					{
						color: '#ffffff',
					},
					{
						lightness: 17,
					},
				],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry.stroke',
				stylers: [
					{
						color: '#ffffff',
					},
					{
						lightness: 29,
					},
					{
						weight: 0.2,
					},
				],
			},
			{
				featureType: 'road.arterial',
				elementType: 'geometry',
				stylers: [
					{
						color: '#ffffff',
					},
					{
						lightness: 18,
					},
				],
			},
			{
				featureType: 'road.local',
				elementType: 'geometry',
				stylers: [
					{
						color: '#ffffff',
					},
					{
						lightness: 16,
					},
				],
			},
			{
				featureType: 'poi',
				elementType: 'geometry',
				stylers: [
					{
						color: '#f5f5f5',
					},
					{
						lightness: 21,
					},
				],
			},
			{
				featureType: 'poi.park',
				elementType: 'geometry',
				stylers: [
					{
						color: '#dedede',
					},
					{
						lightness: 21,
					},
				],
			},
			{
				elementType: 'labels.text.stroke',
				stylers: [
					{
						visibility: 'on',
					},
					{
						color: '#ffffff',
					},
					{
						lightness: 16,
					},
				],
			},
			{
				elementType: 'labels.text.fill',
				stylers: [
					{
						saturation: 36,
					},
					{
						color: '#333333',
					},
					{
						lightness: 40,
					},
				],
			},
			{
				elementType: 'labels.icon',
				stylers: [
					{
						// visibility: 'off',
						visibility: 'on',
					},
					// {
					// 	color: '#707F89',
					// },
					{
						lightness: 70,
					},
				],
			},
			{
				featureType: 'transit',
				elementType: 'geometry',
				stylers: [
					{
						color: '#f2f2f2',
					},
					{
						lightness: 19,
					},
				],
			},
			{
				featureType: 'administrative',
				elementType: 'geometry.fill',
				stylers: [
					{
						color: '#fefefe',
					},
					{
						lightness: 20,
					},
				],
			},
			{
				featureType: 'administrative',
				elementType: 'geometry.stroke',
				stylers: [
					{
						color: '#fefefe',
					},
					{
						lightness: 17,
					},
					{
						weight: 1.2,
					},
				],
			},
		],
	}
}
class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 35.0421231,
			lng: 135.7541996
    },
    zoom: 17
  };
 
  render() {
    return (
      // Important! Always set the container height explicitly
      <MapContainer>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDoXlPKrMG6Ruzu7rTFcALUgkbZEliZQew' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
					options={createMapOptions}
        >
          <AnyReactComponent
            lat={35.0421231}
        		lng={135.7541996}
            text="M"
          />
        </GoogleMapReact>
      </MapContainer>
    );
  }
}
 
export default SimpleMap;

const MapContainer = styled.div`
  height: 350px;
	width: 100%;
	margin-bottom: 48px;
	overflow: hidden;
`
// initially any map object has left top corner at lat lng coordinates
// it's on you to set object origin to 0,0 coordinates
const GreatPlaceStyle = styled.div`
 width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #89849b;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  animation-name: bounce;
  animation-fill-mode: both;
	animation-duration: 1s;
	line-height:0;
	span {
		position:absolute;
		margin: 8px 0 0 8px;
		font-size:14px;
		line-height: 1;
		width:1em;
		height:1em;
		display: block;
		transform: rotate(45deg);
		z-index:1;
		color:#fff;
		font-family:var(--sawarabi-mincho);
	}
  &:after{
    content: '';
    width: 22px;
    height: 22px;
    margin: 4px 0 0 4px;
    background: #2F2F2F;
    position: absolute;
		border-radius: 50%;
		z-index:0;
	}
	@keyframes bounce {
		0%{
			opacity: 0;
			transform: translateY(-2000px) rotate(-45deg);
		}
		60%{
			opacity: 1;
			transform: translateY(30px) rotate(-45deg);
		}
		80%{
			transform: translateY(-10px) rotate(-45deg);
		}
		100%{
			transform: translateY(0) rotate(-45deg);
		}
	}
      /* position: absolute; */
  /* left: -20px;
  top: -20px;
  width: 40px;
  height: 40px;
  border: 5px solid #f44336;
	border-radius: 40px;
	background: #000;
  text-align: center;
  color: '#3f51b5';
  font-size: 16px;
  font-weight: bold;
  padding: 4px; */
`
