//components/image.js
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

const BgSection = ({filename,tag='section',children,...props}) => {

const data = useStaticQuery(graphql`
  query {
    placeholderImage: allFile {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(maxWidth: 3000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`);

  const image = data.placeholderImage.edges.find(n => {
    return n.node.relativePath.includes(filename);
  });
  if (!image) { return null; }

  return (
    <BackgroundImage
      Tag={tag}
      fluid={image.node.childImageSharp.fluid}
      {...props}
    >{children}
    </BackgroundImage>
  )
}

export default BgSection