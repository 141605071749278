import PropTypes from "prop-types"
// import React from "react"
import React, { useState, useEffect } from 'react';
import { Link } from "gatsby"

// import { Helmet } from "react-helmet"
import info from "../config/config"
import Map from "./googlemaps"


const Widgets = ({ type }) => {
	const myFB = React.createRef();
	const [size,setSize] = useState(500);
	function debounce(fn, ms) {
		let timer
		return () => {
			clearTimeout(timer)
			timer = setTimeout(_ => {
				timer = null
				fn.apply(this, arguments)
			}, ms)
		};
	};
	useEffect(() => {
		setSize( myFB.current.offsetWidth );
	}, [myFB]);
	useEffect(() => {
		const debouncedHandleResize = debounce(function handleResize() {
			setSize( myFB.current.offsetWidth );
		}, 1000);
    window.addEventListener('resize', debouncedHandleResize)
    return () => window.removeEventListener('resize', debouncedHandleResize)
  });
	return (
		<div id="info" className="cSection">
		<div className="cTitleArea">
			<div className="cInnerArea">
				<h2 className="cTitle">
					<span className="ja">店舗情報</span>
					<span className="en">Info</span>
				</h2>
			</div>
		</div>
		<div className="cInnerArea">
			<div className="tWidgetWrap">
			<div className="tWidget">
					<h3 className="tWidgetTtl">Access</h3>
					<Map />
					<div className="cShopInfo">
					<h4 className="cShopName"><Link className="cAlpha" to="/">酒場元木</Link> {/* <span>(1F)</span> / <Link className="cAlpha" to="/sakaya/">酒屋元木</Link> <span>(2F)</span> */}</h4>
					<p className="address">
						{info.zipcode}<br/>
						{info.address1} <span className="cNotice">{info.address2}</span>
					</p>

					<p className="tel">
						<a className="cAlpha" href={`tel:${info.tel}`}>{info.tel}</a>
					</p>

					<dl className="datetime">
						<dt>営業時間</dt>
						<dd>{info.closed}</dd>
						<dd>
							{info.openHours1}<br/>
							{info.openHours2}
							</dd>
							<dd className="cNotice"><span className="cNotice">※</span>{info.openHoursNotice} <span className="cNotice">※</span>営業時間が変更となる場合は<a className="cAlpha" href={info.sakabaFB} target="_blank" rel="noreferrer">酒場元木Facebook</a>にてお知らせしております
						</dd>
					</dl>
					</div>
				</div>

				<div className="tWidget">
					<h3 className="tWidgetTtl">
					{(type === 'sakaya')?
						<a className="cAlpha" href={`${info.sakayaFB}`}>Facebook</a>
						:
						<a className="cAlpha" href={`${info.sakabaFB}`}>Facebook</a>
					}
					</h3>
					<div className="tWidgetDetail" id="fbWidget" ref={myFB}>
					{(type === 'sakaya')?
					<iframe title="酒屋元木" src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsakayamotoki%2F&show_posts=true&width=${size}&height=700&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=3232377090213516`} width={size} height="700" style={{border:'none',overflow:'hidden'}} scrolling="no" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
					:
					<iframe title="酒場元木" src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsakabamotoki%2F&show_posts=true&width=${size}&height=700&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=3232377090213516`} width={size} height="700" style={{border:'none',overflow:'hidden'}} scrolling="no" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
					}
					</div>
				</div>
			</div>
		</div>
	</div>
	)
}

Widgets.propTypes = {
  type: PropTypes.string,
}

Widgets.defaultProps = {
  type: ``,
}

export default Widgets
